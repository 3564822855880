import { Component, OnInit } from '@angular/core';
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-conventions',
  templateUrl: './conventions.component.html'
})
export class ConventionsComponents extends AppComponent implements OnInit {

  ngOnInit(): void {
  }

}
