<footer class="text-center text-lg-start bg-dark text-muted">

  <section class="pt-4">
    <div class="container text-center text-md-start mt-5">

      <div class="row mt-3">

        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <p>
            <img src="assets/images/logo/logo-details.png" alt="Dentalma Snc" class="w-100">
          </p>
        </div>

        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-4">
            Informazioni
          </h6>
          <p>
            <a routerLink="" class="text-reset">Home</a>
          </p>
          <p>
            <a routerLink="sicurezza-del-paziente" class="text-reset">Sicurezza del paziente</a>
          </p>
          <p>
            <a routerLink="dove-siamo" class="text-reset">Dove siamo</a>
          </p>
          <p>
            <a routerLink="about" class="text-reset">Convenzioni</a>
          </p>
        </div>

        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

          <h6 class="text-uppercase fw-bold mb-4">
            Terapie svolte
          </h6>
          <p><a class="text-reset" routerLink="terapie-svolte/conservativa-pedodonzia-e-protesi">Conservativa pedodonzia e Protesi</a></p>
          <p><a class="text-reset" routerLink="terapie-svolte/igiene-orale-e-sbiancamento">Igiene orale e Sbiancamento</a></p>
          <p><a class="text-reset" routerLink="terapie-svolte/implantologia">Implantologia</a></p>
          <p><a class="text-reset" routerLink="terapie-svolte/ortodonzia-fissa-e-mobile">Ortodonzia fissa e mobile</a></p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

          <h6 class="text-uppercase fw-bold mb-4">
            Contatti
          </h6>
          <p><i class="fas fa-envelope me-3"></i> {{config.siteInfo.email}}</p>
          <p><i class="fas fa-phone me-3"></i> {{config.siteInfo.phone}}</p>
          <p><i class="fas fa-print me-3"></i> {{config.siteInfo.fax}}</p>
          <p><i class="fas fa-phone me-3"></i> {{config.siteInfo.mobile}}</p>
        </div>

      </div>

    </div>
  </section>
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
    {{currentYearLong}} © {{config.copyright.name}} - <a class="text-reset fw-bold" href="https://{{config.copyright.site}}">{{config.copyright.site}}</a>
  </div>

</footer>
