import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import {Page401Component} from './components/page-error/page401/page401.component';
import {Page404Component} from './components/page-error/page404/page404.component';
import {HomepageComponents} from "./components/page/homepage/homepage.components";
import {PatientSafetyComponents} from "./components/page/patient-safety/patient-safety.components";
import {ContactComponents} from "./components/page/contact/contact.components";
import {ConventionsComponents} from "./components/page/conventions/conventions.components";
import {ConservativePedodonticsAndProsthesesComponents} from "./components/page/therapies/conservative-pedodontics-and-prostheses/conservative-pedodontics-and-prostheses.components";
import {FixedAndMobileOrthodonticsComponents} from "./components/page/therapies/fixed-and-mobile-orthodontics/fixed-and-mobile-orthodontics.components";
import {ImplantologyComponents} from "./components/page/therapies/Implantology/implantology.components";
import {OralHygieneAndWhiteningComponents} from "./components/page/therapies/oral-hygiene-and-whitening/oral-hygiene-and-whitening.components";

const routes: Routes = [

  { path: "home", component: HomepageComponents},
  { path: "sicurezza-del-paziente", component: PatientSafetyComponents},
  { path: "dove-siamo", component: ContactComponents},
  { path: "about", component: ConventionsComponents},

  { path: "terapie-svolte/conservativa-pedodonzia-e-protesi", component: ConservativePedodonticsAndProsthesesComponents},
  { path: "terapie-svolte/igiene-orale-e-sbiancamento", component: OralHygieneAndWhiteningComponents},
  { path: "terapie-svolte/implantologia", component: ImplantologyComponents},
  { path: "terapie-svolte/ortodonzia-fissa-e-mobile", component: FixedAndMobileOrthodonticsComponents},

  { path: "401", component: Page401Component},
  { path: "404", component: Page404Component},


  { path: "**", redirectTo:"home"},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  exports: [RouterModule];
}
