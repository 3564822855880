import { Component, OnInit } from '@angular/core';
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponents extends AppComponent implements OnInit {

  ngOnInit(): void {
  }

}
