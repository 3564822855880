<nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top navbar-custom topnav-navbar">
  <div class="container">
    <a routerLink="home" class="navbar-brand">
      <span class="topnav-logo">
          <img src="assets/images/logo/deltalma-logo.png" alt="Logo Dentalma" height="45px">
      </span>
    </a>
    <h2 class="header-title lh-base text-white">Dentalma Snc</h2>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="sicurezza-del-paziente">Sicurezza del paziente</a>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Terapie Svolte
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" routerLink="terapie-svolte/conservativa-pedodonzia-e-protesi">Conservativa pedodonzia e Protesi</a></li>
            <li><a class="dropdown-item" routerLink="terapie-svolte/igiene-orale-e-sbiancamento">Igiene orale e Sbiancamento</a></li>
            <li><a class="dropdown-item" routerLink="terapie-svolte/implantologia">Implantologia</a></li>
            <li><a class="dropdown-item" routerLink="terapie-svolte/ortodonzia-fissa-e-mobile">Ortodonzia fissa e mobile</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="dove-siamo">Dove siamo</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="about">Convezioni</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
