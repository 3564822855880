import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-safety',
  templateUrl: './patient-safety.component.html'
})
export class PatientSafetyComponents implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
