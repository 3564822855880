<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 text-center">
        <h1>Dove siamo</h1>
      </div>
    </div>
  </div>
</section>

<section id="dove_siamo" class="mt-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8">
        <div class="card ribbon-box">
          <div class="card-body">
            <div class="ribbon-two ribbon-two-warning"><span class="text-light">Mappa</span></div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11890.134053415566!2d12.5833393!3d41.8383421!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70995db903b4a38a!2sDentalma%20Snc!5e0!3m2!1sit!2sit!4v1648671618606!5m2!1sit!2sit" width="100%" height="427" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card ribbon-box">
          <div class="card-body">
            <div class="ribbon-two ribbon-two-warning"><span class="text-light">Orari</span></div>
            <div class="row">
              <div class="col-12"><h3 class="text-end">Orari dello studio</h3></div>
              <div class="fw-bold col-6 mt-1 mb-1 text-end" *ngFor="let info of config.openWork">{{info}}</div>
            </div>
          </div>
        </div>

        <div class="card ribbon-box">
          <div class="card-body">
            <div class="ribbon-two ribbon-two-warning"><span class="text-light">Contatti</span></div>
            <div class="row">
              <div class="col-12"><h3 class="text-end">Informazioni</h3></div>
              <div class="fw-bold col-12 mt-1 mb-1 text-end">Telefono: {{config.siteInfo.phone}}</div>
              <div class="fw-bold col-12 mt-1 mb-1 text-end">Fax: {{config.siteInfo.fax}}</div>
              <div class="fw-bold col-12 mt-1 mb-1 text-end">Cellulare: {{config.siteInfo.mobile}}</div>
              <div class="fw-bold col-12 mt-1 mb-1 text-end">Email: {{config.siteInfo.email}}</div>
              <div class="fw-bold col-12 mt-1 mb-1 text-end">Indirizzo: {{config.siteInfo.address}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
