import { Component, OnInit } from '@angular/core';
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'template-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent extends AppComponent implements OnInit {

  currentYearLong: number = new Date().getFullYear();

  ngOnInit(): void {

  }

}
