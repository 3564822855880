import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixed-and-mobile-orthodontics',
  templateUrl: './fixed-and-mobile-orthodontics.component.html'
})
export class FixedAndMobileOrthodonticsComponents implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
