import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conservative-pedodontics-and-prostheses',
  templateUrl: './conservative-pedodontics-and-prostheses.component.html'
})
export class ConservativePedodonticsAndProsthesesComponents implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
