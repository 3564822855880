<template-conventions class="mt-4" [controlOther]="false"></template-conventions>

<template-parallax [backgroundUrl]="'assets/images/parallax/bg1.jpg'" [text]="'Il nostro studio fa parte della rete odontoiatrica Pronto-Care. <br>Inoltre è possibile ottenere finanziamenti fino a 24 mesi a tasso 0 tramite la nostra convenzione con il Club Medici. <br>Siamo convenzionati anche con la Banca Credito cooperativo bcc (foto della BCC associata)'"></template-parallax>

<div class="conventions container">
  <div class="row">
    <div class="col-12 mt-5 mb-5 text-center">
      <h1>Convenzioni Pronto-Care</h1>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="card">
        <div class="card-header">
          <div class="card-widgets">
            <a data-bs-toggle="collapse" href="#cardCollpase1" role="button" aria-expanded="false" aria-controls="cardCollpase1" class="collapsed"><i class="mdi mdi-minus"></i></a>
          </div>
          <h4 class="card-title mb-0">Gruppi industriali</h4>
        </div>
        <div id="cardCollpase1" class="collapse card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12" *ngFor="let item of config.convention.gruppoIndustriale">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="card">
        <div class="card-header">
          <div class="card-widgets">
            <a data-bs-toggle="collapse" href="#cardCollpase2" role="button" aria-expanded="false" aria-controls="cardCollpase2" class="collapsed"><i class="mdi mdi-minus"></i></a>
          </div>
          <h4 class="card-title mb-0">Gruppi bancari e servizi fiananziari</h4>
        </div>
        <div id="cardCollpase2" class="collapse card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12" *ngFor="let item of config.convention.gruppiBancari">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="card">
        <div class="card-header">
          <div class="card-widgets">
            <a data-bs-toggle="collapse" href="#cardCollpase3" role="button" aria-expanded="false" aria-controls="cardCollpase3" class="collapsed"><i class="mdi mdi-minus"></i></a>
          </div>
          <h4 class="card-title mb-0">Compagnie di servizi</h4>
        </div>
        <div id="cardCollpase3" class="collapse card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12" *ngFor="let item of config.convention.compagnieServizi">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="card">
        <div class="card-header">
          <div class="card-widgets">
            <a data-bs-toggle="collapse" href="#cardCollpase4" role="button" aria-expanded="false" aria-controls="cardCollpase4" class="collapsed"><i class="mdi mdi-minus"></i></a>
          </div>
          <h4 class="card-title mb-0">Istituti enti servizi pubblici</h4>
        </div>

        <div id="cardCollpase4" class="collapse card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12" *ngFor="let item of config.convention.istituti">{{item}}</div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
