import { Component, OnInit } from '@angular/core';
import {AppComponent} from "../../../app.component";
import * as $ from "jquery";

@Component({
  selector: 'template-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent extends AppComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {

  }

}
