<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 mb-5 text-center">
        <h1>Dott. Luigi Di Domenicantonio</h1>
        <h3>Direttore sanitario</h3>
      </div>
      <div class="col-12 text-center">
        L’odontoiatria conservativa si occupa della restaurazione di denti che presentano lesioni dello smalto o della dentina conservativa.
        <br>
        Il termine conservativa indica la conservazione del dente altrimenti distrutto.
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <div class="card">
          <div class="row g-0 align-items-center">
            <div class="col-md-4">
              <img src="assets/images/blog/2.jpg" class="card-img-top" style="min-height:180px" alt="Tecnica diretta">
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">Tecnica diretta</h5>
                <p class="card-text">Consiste nella preparazione della cavità nel dente e nella conseguente otturazione in un’unica seduta</p>
                <p class="card-text"><small class="text-muted">Piccole o medie lesioni</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="row g-0 align-items-center">
            <div class="col-md-4">
              <img src="assets/images/blog/1.jpg" class="card-img-top" style="min-height:180px" alt="Tecnica indiretta">
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">Tecnica indiretta</h5>
                <p class="card-text">
                  Un odontotecnico specializzato si occuperà di creare una protesi che verrà utilizzata per chiudere la cavità</p>
                <p class="card-text"><small class="text-muted">In caso di eccessiva perdita di sostanza dentale</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-5 text-center">
        La preparazione della cavità consiste nell’asportazione dello smalto o
        della dentina dalla carie affinché risulti estremamente ritentiva nei confronti del materiale
        utilizzato per l’otturazione.<br><br>

        Nel caso di carie profonde vi può essere un interessamento della polpa dentale contenente
        anche le fibre nervose.<br>
        Prima di procedere con un intervento conservativo,
        si esegue una cura canalare, anche detta trattamento endodontico o devitalizzazione.<br><br>

        Fino a qualche anno fa il materiale utilizzato per interventi di odontoiatria conservativa
        era l’amalgama d’argento.<br>
        Tale materiale è stato ora quasi completamente rimpiazzato dai
        cosiddetti materiali compositi.
      </div>
    </div>
  </div>
</section>

<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-4 text-center">
        <h3>I nostri obiettivi</h3>
      </div>
      <div class="col-md-4 mt-2 text-center">
        <div class="card bg-warning">
          <div class="card-body">
            <div class="service-icon service-paradontosis"></div>
            <h4 class="card-title text-white">Rimuovere le infezioni</h4>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-2 text-center">
        <div class="card bg-warning">
          <div class="card-body">
            <div class="service-icon service-cracked"></div>
            <h4 class="card-title text-white">Preservare la funzione masticatoria</h4>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-2 text-center">
        <div class="card bg-warning">
          <div class="card-body">
            <div class="service-icon service-implants"></div>
            <h4 class="card-title text-white">Mantenere la lunghezza dell’arcata</h4>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-2 text-center">
        <div class="card bg-warning">
          <div class="card-body">
            <div class="service-icon service-calculus"></div>
            <h4 class="card-title text-white">Stimolare la crescita dell’osso alveolare</h4>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-2 text-center">
        <div class="card bg-warning">
          <div class="card-body">
            <div class="service-icon service-defence"></div>
            <h4 class="card-title text-white">Intercettare abitudini viziate e malocclusioni</h4>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-2 text-center">
        <div class="card bg-warning">
          <div class="card-body">
            <div class="service-icon service-braces"></div>
            <h4 class="card-title text-white">Individuare malocclusioni</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<template-parallax [backgroundUrl]="'assets/images/parallax/bg1.jpg'" [text]="'<h3>La pedodonzia</h3> La pedodonzia è quella branca dell’odontoiatria che si occupa della salute orale dei bambini.<br> Il nostro obbiettivo è quello di consentire al bambino di giungere fino all’età adulta senza problemi di salute orale, intercettando i problemi in fase iniziale.<br> Oggi abbiamo tutti i mezzi e le conoscenze per raggiungere questo importantissimo obiettivo.'"></template-parallax>

<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-4 text-center">
        <h3>Servizi</h3>
      </div>
    </div>
    <div class="row">

      <div class="col-md-4">
        <div class="card text-white bg-dark overflow-hidden">
          <div class="card-body">
            <div class="toll-free-box text-center">
              <h4>Igiene orale</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-white bg-dark overflow-hidden">
          <div class="card-body">
            <div class="toll-free-box text-center">
              <h4>Sigillature e Otturazioni</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-white bg-dark overflow-hidden">
          <div class="card-body">
            <div class="toll-free-box text-center">
              <h4>Terapia pedodontale</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-white bg-dark overflow-hidden">
          <div class="card-body">
            <div class="toll-free-box text-center">
              <h4>Estrazione</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-white bg-dark overflow-hidden">
          <div class="card-body">
            <div class="toll-free-box text-center">
              <h4>Protesi fissa</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-white bg-dark overflow-hidden">
          <div class="card-body">
            <div class="toll-free-box text-center">
              <h4>Corone zirconio ceramica</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mt-4 mb-4 text-center">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-4 text-center">
        <h3>Informazioni sulle corone</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        Non eseguire la corona consigliata significa sottoporre il dente ad un’elevato rischio di frattura con conseguente necessità di estrazione dello stesso.
      </div>
      <div class="col-md-12">
        La realizzazione di corone in zirconio richiede elaborate fasi di lavorazione da parte del laboratorio che
        utilizza apparecchiature molto sofisticate il cosiddetto sistema <b>CAD CAM</b>
      </div>
      <div class="row mt-5">
        <div class="col-md-6">
          <div class="card">
            <div class="row g-0 align-items-center">
              <div class="col-md-4">
                <img src="assets/images/blog/3.jpg" class="card-img-top" style="min-height:150px" alt="Corone zirconio ceramica">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Corone zirconio ceramica</h5>
                  <p class="card-text">Attraverso il sistema cad cam si eseguono tutte le fasi di progettazione al computer</p>
                  <p class="card-text"><small class="text-muted">Risultati finali superiori a qualsiasi altro metodo</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="row g-0 align-items-center">
              <div class="col-md-4">
                <img src="assets/images/blog/4.jpg" class="card-img-top" style="min-height:150px" alt="Corone in disilicato">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Corone in disilicato</h5>
                  <p class="card-text">Completamente prive di metallo e costituite da sola ceramica</p>
                  <p class="card-text"><small class="text-muted">I restauri in disilicato di litio offrono soluzioni altamente estetiche</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-5 text-center">
          La preparazione della cavità consiste nell’asportazione dello smalto o
          della dentina dalla carie affinché risulti estremamente ritentiva nei confronti del materiale
          utilizzato per l’otturazione.<br><br>

          Nel caso di carie profonde vi può essere un interessamento della polpa dentale contenente
          anche le fibre nervose.<br>
          Prima di procedere con un intervento conservativo,
          si esegue una cura canalare, anche detta trattamento endodontico o devitalizzazione.<br><br>

          Fino a qualche anno fa il materiale utilizzato per interventi di odontoiatria conservativa
          era l’amalgama d’argento.<br>
          Tale materiale è stato ora quasi completamente rimpiazzato dai
          cosiddetti materiali compositi.
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mt-4 mb-4 text-center">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-4 text-center">
        <h3>Protesi mobile</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              Caratteristiche
            </h3>
          </div>
          <div class="card-body">
            <p class="card-text">
              Le protesi flessibili sono prodotti biocompatibili che presentano caratteristiche di altissima
              flessibilità e allo stesso tempo sono indistruttibili ed ultra leggere.<br><br>
              La biocompatibilità è un requisito fondamentale, legato alla necessità di migliorare e/o
              ripristinare una determinata funzione biologica, <br>
              senza interferire o interagire in modo dannoso
              con le attività fisiologiche dell’organismo.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              Vantaggi ed indicazioni
            </h3>
          </div>
          <div class="card-body">
            <p class="card-text">
              I vantaggi delle protesi in Nylon sono
            </p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">L’assenza di ganci metallici</li>
              <li class="list-group-item">Più resistenti alle macchie rispetto ad altri materiali acrilici</li>
              <li class="list-group-item">Grado di flessibilità perfetto, non si deformano né si indeboliscono</li>
              <li class="list-group-item">Più confortevoli, più durature e più resistenti delle protesi tradizionali</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
