import {Component, OnInit} from '@angular/core';
import {ConfigApp} from '../../../config';

@Component({
  selector: 'template-preload',
  template: `
    <div id="preload">
      <div id="preload-content">
        <div class="cssload-loader">
          <div class="cssload-inner cssload-one"></div>
          <div class="cssload-inner cssload-two"></div>
          <div class="cssload-inner cssload-three"></div>
        </div>
      </div>
    </div>`,
  styleUrls: ['./preload.component.css']
})

export class PreloadComponent implements OnInit {

  ngOnInit(): void {
    const note = document.getElementById('preload');
    note.style.background = ConfigApp.applicationSettings.preloadBackground;
  }

}
