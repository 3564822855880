import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'template-conventions',
  template: `
    <section class="mt-4 mb-4">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mt-5 mb-5 text-center">
            <h1>Convenzioni</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" *ngFor="let conv of config.conventions">
            <div class="card p-3">
              <img src="assets/images/convenzioni/{{conv.image}}" alt="{{conv.title}}" title="{{conv.title}}">
            </div>
          </div>
          <div class="col-md-3" *ngIf="controlOther">
            <div class="card text-white bg-warning overflow-hidden">
              <div class="card-body">
                <div class="toll-free-box text-center">
                  <h4>
                    <a routerLink="/about" class="btn fs-3 fw-bold" style="height: 62px">Altre convenzioni</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>`,
  styleUrls: ['./convention.component.css']
})

export class ConventionComponent {
  @Input() controlOther:boolean = true;
  //@ts-ignore
  config=window.getAppConfig();


}
