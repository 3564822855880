import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oral-hygiene-and-whitening',
  templateUrl: './implantology.component.html'
})
export class ImplantologyComponents implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
