<section id="sliderHomepage" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/images/slider/1.jpg" class="d-block w-100" alt="">
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/2.jpg" class="d-block w-100" alt="">
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/3.jpg" class="d-block w-100" alt="">
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/4.jpg" class="d-block w-100" alt="">
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/6.jpg" class="d-block w-100" alt="">
    </div>
    <div class="carousel-item">
      <img src="assets/images/slider/7.jpg" class="d-block w-100" alt="">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#sliderHomepage" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#sliderHomepage" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</section>

<section class="container-fluid">
  <div class="row">
    <div class="col-12 mt-5 mb-5 text-center">
      <h1>La nostra equipe</h1>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let team of config.staff;let i=index" class="col-sm-6 col-lg-4">
      <div class="card">
        <div class="row g-0 align-items-center">
          <div class="col-md-4">
            <div class="image-staff" style="background-image: url(assets/images/staff/{{team.image}})" title="{{team.name}}"></div>
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{{team.name}}</h5>
              <h6 class="card-subtitle text-muted">{{team.role}}</h6>
            </div>
            <div class="card-footer text-muted " *ngIf="team.description">
              <p class="card-text mb-0" >
                <small class="text-muted">
                  <a data-bs-toggle="collapse" href="#staff_{{i}}" role="button" aria-expanded="true" aria-controls="staff_{{i}}" class="text-secondary fw-bold">
                    Dettagli
                  </a>
                </small>
              </p>
              <p id="staff_{{i}}" class="collapse card-text fst-italic mt-3">{{team.description}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<template-parallax [backgroundUrl]="'assets/images/parallax/bg5.jpg'" [text]="'Il sorriso è il nostro primo biglietto da visita'"></template-parallax>

<app-contact></app-contact>

<template-conventions></template-conventions>
