import {Component, OnInit} from '@angular/core';
import {ConfigApp,} from '../../../config';

@Component({
  selector: 'template-cookie',
  template: `
    <div class="container-fluid" *ngIf="!acceptCookieControl">
      <div class="row">
        <div class="col-md-12">
          <div class="header-cookie">
            Questo sito utilizza cookie che ci aiutano a fornire i nostri servizi. Continuando la navigazione, accetti il nostro utilizzo dei cookie

            <button class="btn btn-primary" (click)="acceptCookie()">
              Va Bene
            </button>

          </div>
        </div>
      </div>
    </div>`,
  styleUrls: ['./cookie.component.css']
})

export class CookieComponent implements OnInit {

  acceptCookieControl:boolean = false;

  ngOnInit(): void {

  }

  acceptCookie(){
    this.acceptCookieControl = true;
  }
}
