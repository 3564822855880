<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 mb-5 text-center">
        <h1>Dott.ssa Ilaria Manganaro</h1>
        <h3>Igienista dentale</h3>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <div class="card">
          <img class="card-img-top" src="assets/images/gallery/6.jpg" alt="Igiene dentale">
          <div class="card-body">
            <h5 class="card-title">Igiene dentale</h5>
            <p class="card-text">
              L’igiene dentale è molto importante e si effettua per prevenire le affezioni dei denti e della bocca, compresa la pulizia dei denti. L’igiene dentale serve per eliminare i residui accumulatesi nel tempo, quindi rendere la cavità orale più pulita possibile cercando di espellere i residui di placca batterica, come il tartaro che può essere sottogengivale e sopragengivale. Il tartaro è un deposito che è dovuto dalla presenza di sali di calcio nella placca e nella saliva. Il tartaro sottogengivale è quello più consistente e si calcifica in profondità nel solco gengivale e questi accumuli contengono sostanze dannose per i tessuti gengivali. Con l’igiene dentale si va a togliere ogni residuo di tartaro e macchie senza danneggiare lo smalto.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <img class="card-img-top" src="assets/images/gallery/5.jpg" alt="Sbiancamento dentale">
          <div class="card-body">
            <h5 class="card-title">Sbiancamento dentale</h5>
            <p class="card-text">
              Lo sbiancamento dentale è un metodo indicato per le persone che vogliono ottenere un sorriso bianco in modo efficace. I sitemi per lo sbiancamento dentale possono essere con attivazione luminosa o senza. Le lampade per lo sbiancamento dentale sono dotate di un cristallo che diffonde la luce a entrambe le arcate contemporaneamente. Per lo sbiancamento dentale ci sono diversi tipi di trattamenti, come quello basato tu tecniche che utilizzano perossido di idrogeno che agisce tramite l’ossigeno e si libera durante l’applicazione e riuscendo così a penetrare nello smalto. L’intensità dello sbiancamento dentale in genere dipende dalla concentrazione dei principi attivi e dal suo tempo di posa sui denti.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

