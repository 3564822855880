import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { HeaderComponent } from './components/template/header/header.component';
import { FooterComponent } from './components/template/footer/footer.component';
import { Page401Component } from './components/page-error/page401/page401.component';
import { Page404Component } from './components/page-error/page404/page404.component';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';

import {PreloadComponent} from "./components/template/preload/preload.component";
import {PatientSafetyComponents} from "./components/page/patient-safety/patient-safety.components";
import {ConventionsComponents} from "./components/page/conventions/conventions.components";
import {ContactComponents} from "./components/page/contact/contact.components";
import {ConservativePedodonticsAndProsthesesComponents} from "./components/page/therapies/conservative-pedodontics-and-prostheses/conservative-pedodontics-and-prostheses.components";
import {FixedAndMobileOrthodonticsComponents} from "./components/page/therapies/fixed-and-mobile-orthodontics/fixed-and-mobile-orthodontics.components";
import {ImplantologyComponents} from "./components/page/therapies/Implantology/implantology.components";
import {OralHygieneAndWhiteningComponents} from "./components/page/therapies/oral-hygiene-and-whitening/oral-hygiene-and-whitening.components";
import {CookieComponent} from "./components/template/cookie/cookie.component";
import {HomepageComponents} from "./components/page/homepage/homepage.components";
import {ParallaxComponent} from "./components/template/parallax/parallax.component";
import {ConventionComponent} from "./components/template/convention/convention.component";




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    Page401Component,
    Page404Component,
    PreloadComponent,
    PatientSafetyComponents,
    ConventionsComponents,
    ContactComponents,
    PatientSafetyComponents,
    ConservativePedodonticsAndProsthesesComponents,
    FixedAndMobileOrthodonticsComponents,
    ImplantologyComponents,
    OralHygieneAndWhiteningComponents,
    CookieComponent,
    HomepageComponents,
    ParallaxComponent,
    ConventionComponent
  ],
  imports: [BrowserModule, AppRoutingModule, FormsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
