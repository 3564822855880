<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 mb-5 text-center">
        <h1>Sicurezza del paziente</h1>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-4 patient-safety">
    <div class="row">
      <div class="col-md-4 col-sm-6">
        <div class="card">
          <img src="assets/images/patient-safety/1.jpg" class="card-img-top" alt="Card image cap">
          <div class="card-body">
            <h4 class="card-title">Sterilizzazione</h4>
            <p class="card-text">
              Qualsiasi strumento che venga in contatto con i pazienti, dopo l’utilizzo, se riutilizzabile, viene sterilizzato per ogni singolo paziente.<br/><br/>
              Nei casi in cui la sterilizzazione non sia tecnicamente possibile, è obbligatoria la disinfezione degli strumenti con sostanze chimiche di riconosciuta efficacia.<br/><br/>
              Questo studio ha stipulato un contratto con una ditta autorizzata allo smaltimento dei rifiuti speciali e\o pericolosi.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card">
          <img src="assets/images/patient-safety/3.jpg" class="card-img-top" alt="Card image cap">
          <div class="card-body">
            <h4 class="card-title">Adeguamento impianti</h4>
            <p class="card-text">
              L’impianto elettrico dello studio soddisfa i requisiti di legge (Legge 46/1990 – Norme CEI 64-4), a cui devono sottostare tutti gli ambulatori medici di tipo A.<br/><br/>
              La verifica dell’impianto elettrico viene effettuata con cadenza biennale come da legge.<br/><br/>
              Lo studio ed il suo titolare operano nel rispetto delle norme di tutela della salute e sicurezza sui luoghi di lavoro (Legge 626/94).
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="card">
          <img src="assets/images/patient-safety/2.jpg" class="card-img-top" alt="Card image cap">
          <div class="card-body">
            <h4 class="card-title">Emergenze sanitarie</h4>
            <p class="card-text">
              Nello studio sono sempre presenti farmaci e materiali necessari in caso di emergenze sanitarie.
            </p>
            <h5 class="card-title">Privacy</h5>
            <p class="card-text">
              Vengono rispettati tutti gli adempimenti previsti dalla legge sulla privacy attualmente in vigore (Dlgs. 196 del 30/06/03 e successivi).
            </p>
            <h5 class="card-title">Antincendio</h5>
            <p class="card-text">Rispetto delle norme antincendio (DM del 18 settembre 2002 e DM del 10 marzo 1998)<br/><br/>
              I bagni sono progettati a norma di legge per l’utilizzo da parte dei disabili.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
