import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'template-parallax',
  template: `
    <section class="divider parallax layer-overlay overlay-theme-colored1-8 mt-5 mb-5" style="background-image: url({{backgroundUrl}}); background-position: 50% 0px;">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-12 w-100 h-100 d-flex align-items-center justify-content-center">
            <h3 class="text-center text-white" [innerHTML]="text"></h3>
          </div>
        </div>
      </div>
    </section>`,
  styleUrls: ['./parallax.component.css']
})

export class ParallaxComponent {

  @Input() backgroundUrl:string;
  @Input() text:string;


}
