<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 mb-5 text-center">
        <h1>Dott. Guzzo Federico</h1>
        <h3>Implantologo</h3>
      </div>
      <div class="col-12 text-center">
        In questi ultimi anni ci siamo tutti abituati ad accettare ogni tipo di novità con tale disinvoltura e frequenza, che spesso non abbiamo il tempo di renderci conto di quanto sia migliorata la nostra vita, di come possiamo sempre meglio risolvere i nostri problemi, di come anche la medicina ci propone nuove metodiche per risolvere i malanni che, talvolta, in modo inevitabile, con l’avanzare dell’età dobbiamo affrontare.
        Tra le nuove possibilità che la medicina e chirurgia offre a livello di ripristino protesico, certamente merita particolare attenzione una tecnica che negli ultimi anni ha avuto uno sviluppo non indifferente: l’implantologia.
        <br><br>
        In realtà l’implantologia orale, non completamente nuova, negli ultimi anni ha solamente avuto una maggiore pubblicità, che come conseguenza ha portato a più ampia conoscenza e diffusione di una tecnica che ha abbondantemente superato la fase sperimentale, grazie anche a molti odontoiatri italiani che sono stati tra i pionieri nel mondo e che da decenni vi si dedicano con impegno e notevole successo.
        <br><br>
        Una ventina di anni fa, ogni qualvolta proponevo ai miei pazienti la possibiltà di ricorrere agli impianti, dovevo tenere un minicorso per spiegare loro cosa fosse l’implantologia, le possibilità che già allora offriva, nonchè sfatare inutili timori legati ad una tecnica chirurgica che era del tutto sconosciuta o peggio ancora considerata molto rischiosa.
        <br><br>
        Il termine implantologia è stato, infatti, per molto tempo associato a quello di rigetto, ma in realtà ciò che per anni non ha permesso a questa tecnica di diffondersi è stato solo il rigetto per l’implantologia che molti odontoiatri, anche illustri, hanno mostrato di avere per una branca dell’odontoiatria che può risolvere nel modo più fisiologico il problema dell’edentulia.
        <br><br>
        Oggi, grazie alla diffusione che l’implantologia ha avuto tra gli odontoiatri, agli indiscussi risultati ed allo spazio sempre maggiore che i mass midia vi hanno dedicato, sono quasi sempre i pazienti che, quando hanno bisogno di protesi, consapevolmente la richiedono.
        Purtroppo la rapida diffusione di questa tecnica ha determinato ulteriori e non trascurabili problemi. Molti odontoiatri, attirati da apparenti facili guadagni o per evitare che loro pazienti potessero rivolgersi ad altri colleghi, hanno iniziato ad impiantare viti, cilindri e persino lame, senza avere non solo una valida esperianza, ma spesso nemmeno quelle nozioni di base indispensabili per conoscere le indicazioni, le tecniche chirurgiche ed i limiti necessari per offrire ai pazienti una buona implantologia.
        Una colpa, non certo secondaria, va data anche ad alcune industrie che producono impianti, le quali, pur di vendere, offrono a basso costo materiali di non provata qualità, forniti di manuale di istruzione, come se il tutto si risolvesse nell’avvitare o inchiodare un manufatto nell’osso.
        Per contro, altre industrie organizzando corsi teorico-pratici hanno permesso a molti odontoiatri di acquisire una adeguata conoscenza e professionalità sull’implantologia, dando loro la possibiltà di intervenire sui pazienti con la dovuta metodica e competenza che i diversi casi richiedono.
        Ma che cos’è l’implantologia, quali sono le possibilità che offre nella riabilitazione protesica orale?
        L’implantologia ci consente di realizzare delle protesi odontoiatriche, fissando le stesse non più sui denti o sulle gengive, ma direttamente nell’osso.
        <br><br>
        Senza ricorrere a questa tecnica, l’unico sistema per realizzare delle protesi fisse consiste nel monconizzare elementi dentari in modo da trasformarli in pilastri di appoggio per ponti. Risulta evidente che in questo modo, non solo siamo costretti ad intervenire spesso su denti sani ed a realizzare protesi molto più estese rispetto alla reale edentulia, ma in molti casi non abbiamo assolutamente la possibilità di protesizzare in modo fisso per mancanza di denti sui quali poggiare i manufatti protesici.
        L’implantologia ci permette di inserire manufatti di titanio direttamente nell’osso in modo da creare una radice artificiale da usare poi per il fissaggio delle protesi.
        <br><br>
        Con questo sistema affrontiamo il problema delle edentulie nel modo più fisiologico: la mancanza di un elemento dentario, infatti, viene risolto ricostruendo l’intero dente: la radice viene ricreata inserendo l’impianto, e la corona vi si poggia in modo esclusivo, senza assolutamente intaccare i denti contigui.
        Gli impianti possono, inoltre, essere usati come monconi per ponti, quando estese edentulie o situazioni ossee particolari non permettono di sostituire ogni singola radice. Sono queste le classiche situazioni in cui mancando l’intero gruppo molare, ed a volte anche i premolari di una emiarcata, si era costretti a ricorrere alle protesi mobili per mancanza di monconi distali sui quali poggiare i ponti.
        L’implantologia consente persino di risolvere problemi di edentulia totale, permettendo a quei pazienti che hanno provato la classica dentiera, che hanno quindi vissuto una grave situazione di disagio, sia fisico che psicologico, di riavere dei denti fissi, di riacquistare nuovamente una valida e fisiologica masticazione.
        <br><br>
        Persino nei casi più gravi di atrofia ossea, in quei pazienti che per anni, portando protesi mobili totali, hanno praticamente distrutto ogni accenno di cresta ossea, e che, quindi, sono costretti all’uso continuo di polverine o pomate adesive, l’implantologia può risolvere il problema. In questi casi, infatti, alcuni impianti inseriti nell’osso residuo o appoggiati sullo stesso sotto al periostio, sono sufficienti per fissare una protesi mobile realizzata con particolari attacchi e permettere al paziente una buona masticazione.
        <br><br>
        C’è da augurarsi, pertanto, che l’implantologia possa entrare adeguatamente in ogni studio odontoiatrico così come è stato per la conservativa, l’ortodonzia e la protesica, in modo da offrire ai pazienti quelle metodiche che inducono a conservare nei musei dell’odontoiatria le vecchie dentiere che divertono tanto i nipoti quando fanno le bollicine, come recitava una nota pubblicità, e ridare un sano e bel sorriso anche ai nonni.

      </div>
    </div>
  </div>
</section>

