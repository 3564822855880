<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 mb-5 text-center">
        <h1>Dott. Vitaliano Dell’Olio</h1>
        <h3>Ortodonzista</h3>
      </div>
      <div class="col-12 text-center">
        L’ ortodonzia è la branca dell’ odontoiatria che si occupa della prevenzione e della cura delle malocclusioni.
        La causa di molte malocclusioni va ricercata nella genetica del paziente, ma altre si sviluppano per
        motivi cosiddetti ambientali. <br><br>

        Fondamentale risulta essere il ruolo che esercita la ortodonzia fissafunzione della muscolatura oro-facciale nello sviluppo scheletrico dei mascellari e della dentatura. I piu frequenti fattori ambientali capaci di alterarne l’ equilibrio sono: riniti ricorrenti, il protrarsi di abitudini viziate (succhiamento del dito o ciuccio). A volte i fattori ambientali si uniscono a quelli genetici determinando le alterazioni allo sviluppo dei mascellari.
        Il termine ortodonzia significa “denti dritti”, infatti questa branca dell’ odontoiatria si occupa dell’ allineamento dei denti, migliorando l’ estetica e la funzionalità dell’ apparato masticatorio. La durata di un trattamento ortodontico è di circa 2 anni , durante i quali vengono effettuati controlli periodici.
        <br><br>
        Gli apparecchi di tipo fisso non possono essere rimossi dal paziente e ciò garantisce sulla qualità e la riuscita del trattamento, nel rispetto dei tempi prefissati dal medico. Esistono due tipi di apparecchiature ortodontiche , una in metallo, l’ altra estetica in ceramica.
        <br><br>
      </div>
      <div class="offset-md-3 col-md-6 mt-5 mb-5">
        <div class="card p-3">
          <img src="assets/images/blog/7.jpg">
        </div>
      </div>
      <div class="col-md-12 text-center">
        S’intende per ortodonzia mobile l’utilizzo di apparecchi che è il paziente è in grado di applicare e rimuovere dalle arcate dentarie.
        Ci riferiamo ad apparecchi meccanici e funzionali.
        Apparecchi mobili meccanici sono formati da un corpo in resina acrilica che trattiene nella sua struttura sia gli elementi che ne permettono l’aggancio ai denti sia quelli più propriamente meccanici (viti, molle archi) che una volta attivati sono in grado di spostare i denti. La correzione è determinata da un’azione prevalente sulle posizioni dentali.
        Altri apparecchi di ortodonzia che il paziente può applicare e rimuovere sono di tipo funzionale. Essi si caratterizzano per il fatto che le forze in grado di determinare la correzione della malocclusione sono estrinseche all’apparecchio. Questo tipo di apparecchi agisce sia sulle posizioni dentali che sulla crescita scheletrica, correggendo la malocclusione con un vero e proprio effetto combinato, ortodontico e ortopedico.

        <br><br>
        La nostra ortodonzia trasparente è realizzata con invisalign

      </div>
      <div class="offset-md-4 col-md-4 mt-5">
        <div class="card p-3">
          <img src="assets/images/convenzioni/invisalign.png" alt="Invisalign " title="Invisalign">
        </div>
      </div>
    </div>
  </div>
</section>

