import { Component } from '@angular/core';
import * as $ from 'jquery';

import { ConfigApp } from './config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  pageTitle: string | undefined;

  config: any;

  constructor() {
    this.config = ConfigApp;
  }

  ngOnInit(){
    let that = this;
    setTimeout(function(){
      that.startFunctionNotLoaded();
      AppComponent.hideLoading();
    },500);

  }

  startFunctionNotLoaded(){
    // @ts-ignore
    window.$('*[data-toggle="tooltip"]').tooltip();

    if(this.config.themes.collapseAsideType === 'none'){
      let body = document.getElementsByTagName('body')[0];
      body.classList.add('collapseAsideTypeNone');
    }

    setTimeout(()=>{
      this.parallax();
    },500);
  }

  parallax(){
    if($('.parallax').length){


      function parallax_event(classDiv){
        var backgroundPos = jQuery(classDiv).css('background-position').split(" ");
        // @ts-ignore
        var xPos = backgroundPos[0], yPos = (backgroundPos[1].replace('px',''))*1;

        jQuery(window).stop().scroll(function() {

          var scrollTop = $(window).scrollTop();

          /* console.log('scrolltop: '+scrollTop);
           console.log('il valore y ÃƒÂ¨: '+xPos);
           console.log('il valore y + scroll ÃƒÂ¨: '+(yPos+scrollTop));*/


          var div_height = jQuery(classDiv).height();
          var speed = jQuery(classDiv).data('speed');

          if(scrollTop>0){

            jQuery(classDiv).css('background-position', xPos+(yPos+(scrollTop)/speed)+'px');

          }else if(scrollTop==0){

            jQuery(classDiv).css('background-position', xPos+yPos+'px');


          }

        });

      }
      parallax_event('.parallax');
    }
  }

  changePageTitle(namePage){
    ConfigApp.applicationSettings.titleApplication = ConfigApp.applicationSettings.titlePage + " - " + namePage;
  }

  static showLoading() {
    $('html').addClass('overflow-hidden');
    $('#preload').show();
  }

  static hideLoading() {
    $('html').removeClass('overflow-hidden');
    $('#preload').hide();
  }
}
